/*
 * @Author: yangliao
 * @Date: 2020-09-24 09:10:34
 * @LastEditTime: 2021-07-22 13:38:42
 * @LastEditors: yangliao
 * @Description: 校验规则
 * @FilePath: /netHallOfficialAccounts/src/utils/validator.js
 */

/* 手机号校验 */
export const isMobile = (val) => {
  const patrn = /^(?:(?:\+|00)86)?1[3-9]\d{9}$/;
  return patrn.test(val);
};

/* 身份证校验 */
export const identityId = (val) => {
  const patrn = /^[1-9]\d{5}(?:18|19|20)\d{2}(?:0[1-9]|10|11|12)(?:0[1-9]|[1-2]\d|30|31)\d{3}[\dXx]$/;
  return patrn.test(val);
};

/* 港澳通行证、台湾同行证校验 */
export const gatePassCode = (val) => {
  const patrn = /^[0-9A-Za-z]{9}$/;
  return patrn.test(val);
};

/*护照校验 */
export const passport = (val) => {
  const patrn = /^([0-9A-Za-z]{8}|[0-9A-Za-z]{9})$/;
  return patrn.test(val);
};

/* 名称校验 */
export const checkName = (val) => {
  const patrn = /^[\u4e00-\u9fa5]{2,10}$/;
  return patrn.test(val);
};

/* 邮箱校验 */
export const isEmail = (val) => {
  const patrn = /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/;
  return patrn.test(val);
};

/* 验证码六位数字 */
export const isTellCode = (val) => {
  const patrn = /^\d{6}$/;
  return patrn.test(val);
};

/* 金额输出两位小数 */
export const isAmount = (num) => {
  let val = num.toString();
  if (val) {
    const list = val.split('.');
    if (list.length === 1) {
      val = `${val}.00`;
    } else if (list.length === 2) {
      const decimal = list[1];
      if (decimal.length < 2) {
        val = `${val}0`;
      } else if (decimal.length > 2) {
        val = `${list[0]}.${decimal.substring(0, 2)}`;
      }
    }
  }
  return val;
};
